<template>
  <div>
    <v-container>
      <h2 class="mt-5 mb-5">Add A New Project</h2>
      <v-divider class="grey darken--4"></v-divider>
      <v-layout row wrap>
        <v-flex xs12 md8>
          <v-layout row wrap class="mt-10">
            <v-flex xs12 md6 class="pa-2">
              <p class="ma-0"><b>Title</b></p>
              <v-text-field
                single-line
                outlined
                v-model="title"
                label="Title"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <p class="ma-0"><b>Client</b></p>
              <v-text-field
                single-line
                outlined
                v-model="client"
                label="Client"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md12 class="pa-2">
              <p class="ma-0"><b>Tagline</b></p>
              <v-text-field
                single-line
                outlined
                v-model="tagline"
                label="Tagline"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md12 class="pa-2">
              <p class="ma-0"><b>Quote</b></p>
              <v-text-field
                single-line
                outlined
                v-model="quote"
                label="Quote"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 md6 class="pa-2">
              <p class="ma-0"><b>Location</b></p>
              <v-text-field
                single-line
                outlined
                v-model="location"
                label="Location"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <p class="ma-0"><b>Project Area</b></p>
              <v-text-field
                single-line
                outlined
                v-model="projectArea"
                label="Project Area"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <p class="ma-0"><b>Website</b></p>
              <v-text-field
                single-line
                outlined
                v-model="website"
                label="Website"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <p class="ma-0">
                <b>Type </b>
                <span class="grey--text text--darken-1"
                  >(New/Ongoing/Completed)</span
                >
              </p>
              <v-select
                single-line
                outlined
                :items="types"
                v-model="type"
                label="Type"
              ></v-select>
            </v-flex>
            <v-flex xs12 md12 class="pa-2">
              <p class="ma-0"><b>Description</b></p>
              <v-textarea
                single-line
                outlined
                v-model="description"
                label="Description"
              ></v-textarea>
            </v-flex>
            <v-flex xs12 md12 class="pa-2">
              <v-btn @click="addProject" class="primary" x-large
                >Add Project</v-btn
              >
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs2 md4 class="pa-10">
          <p class="mt-2"><b>Images</b></p>
          <uploader @uploadSuccess="uploadSuccess"></uploader>
          <div class="mt-5">
            <v-layout row wrap>
              <v-flex xs12 md6 v-for="item in images" :key="item" class="pa-1">
                <div
                  class="pa-2 pb-0 picture grey darken-4"
                  style="position: relative"
                >
                  <div style="position: absolute; top: 5px; right: 5px">
                    <v-btn
                      class="grey darken-2"
                      x-small
                      @click="removeImage(item)"
                      ><span small class="">X</span></v-btn
                    >
                    <v-btn
                      class="grey darken-2 ml-2"
                      x-small
                      @click="setFeatured(item)"
                      ><v-icon small v-if="item == featured_image" class="red--text">mdi-star</v-icon><v-icon small v-else>mdi-star</v-icon></v-btn
                    >
                  </div>

                  <img
                    :src="item"
                    style="max-width: 100%"
                    @click="viewImage(item)"
                  />
                </div>
              </v-flex>
            </v-layout>
          </div>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 class="mt-5">
          <v-divider class="mt-5 mb-5"></v-divider>
          <h3 class="mb-5">Instructions</h3>
          <p>1. Drag and drop files to the upload box to upload images.</p>
          <p>2. For optimum perfomance the file sizes must be optimised preferably under 200KB each.</p>
          <p>3. WEBP format can prefered for the images for optimum perfomance</p>
          <p>4. Ideally the images should be sized 1920 x 1080 for better asthetics.</p>
          <p>5. Using images in portrait mode are discouraged</p>
          <p>6. Every project jas a featured image which is highlghted on the profile page. Featured image can be selected by pressing the star <v-icon>mdi-star</v-icon> button.</p>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="dialog" width="900">
      <v-card class="pa-2 pb-0">
        <img :src="selectedImage" style="width: 100%" />
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          shaped
          right
          top
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Uploader from "../components/Uploader.vue";
import Axios from "axios";
import { BASE_URL } from "../config";
export default {
  data() {
    return {
      title: "",
      tagline: "",
      quote: "",
      client: "",
      location: "",
      projectArea: "",
      description: "",
      website: "",
      images: [],
      featured_image: "",
      type: "",
      types: ["New", "Ongoing", "Completed"],
      dialog: false,
      notification: "",
      snackbar: false,
    };
  },
  components: {
    Uploader,
  },
  methods: {
    async addProject() {
      let url = BASE_URL + "/project";
      let payload = {
        title: this.title,
        tagline: this.tagline,
        quote: this.quote,
        client: this.client,
        location: this.location,
        projectArea: this.projectArea,
        description: this.description,
        website: this.website,
        images: this.images,
        type: this.type,
        featured_image : this.featured_image
      };
      let { data } = await Axios.post(url, payload);
      this.title = "";
      this.tagline = "";
      this.quote = "";
      this.client = "";
      this.location = "";
      this.projectArea = "";
      this.description = "";
      this.website = "";
      this.images = [];
      this.type = "";
      this.notification = "Project created!";
      this.snackbar = true;
    },
    removeImage(image) {
      this.images = this.images.filter((item) => item !== image);
    },
    viewImage(image) {
      this.selectedImage = image;
      this.dialog = true;
    },
    setFeatured(item){
      this.featured_image = item
    },
    uploadSuccess(value) {
      if (this.featured_image == "") {
        this.featured_image = value;
      }
      this.images.push(value);
    },
  },
};
</script>
<style>
.picture img {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
</style>